import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux'

import './index.scss';

function QuantizeSize(props) {
	const [quantizeTimer, setQuantizeTimer] = useState();
	const [hideQuantize, setHideQuantize] = useState(false);

	useEffect(() => {
		clearTimeout(quantizeTimer);
		setHideQuantize(false)

		let timer = setTimeout(() => {
			setHideQuantize(true)
		}, 300)
		setQuantizeTimer(timer)

		console.log('changing quantize size');
	}, [props.quantizeCurrentStepsize])

	return (
		<div className={`quantizesize ${hideQuantize ? 'hide' :  ''}`}>
			{props.quantizeStepsizes[props.quantizeCurrentStepsize]}
		</div>
	)
}

export default connect((state) => ({
	quantizeStepsizes: state.Sequencer.quantizeStepsizes,
	quantizeCurrentStepsize: state.Sequencer.quantizeCurrentStepsize
}))(QuantizeSize);