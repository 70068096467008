import Tone from 'tone'
import {store} from './../Store'
import {db} from './../../../index.js'

var dbHandler = {
	init: () => {
		console.log('init firebase');
		// get song from firebase
		
			// song
				db.collection('songs').doc('ZaDy9Hzhzch2Z11hueni').get().then((doc) => {
					store.dispatch({
						type: 'SET_SONG',
						song: doc.data()
					})

					store.dispatch({
				    	type: 'SET_TEMPO',
				    	bpm: doc.data().bpm
					})
				})

			// tracks
				db.collection('songs').doc('ZaDy9Hzhzch2Z11hueni').collection('tracks').get().then((querySnapshot) => {
					var tracks = []
					querySnapshot.forEach((doc) => {
						tracks.push({id: doc.id, data: doc.data()})
						// console.log('trackId', doc.id, '-> muted', doc.data().mute);
					})
					store.dispatch({
						type: 'SET_TRACKS',
						tracks: tracks
					})
				})

			// scenes
				db.collection('songs').doc('ZaDy9Hzhzch2Z11hueni').collection('scenes').get().then((querySnapshot) => {
					var scenes = []
					querySnapshot.forEach((doc) => {
						console.log(doc.data(), doc.id)

						var scene = {
							id: doc.id,
							tracks: doc.data().tracks
						}
						scenes.push(scene)
						// console.log('trackId', doc.id, '-> muted', doc.data().mute);
					})
					store.dispatch({
						type: 'SET_SCENES',
						scenes: scenes
					})
				})
	},

	addNoteToTrack: (trackId, note) => {
		var trackNum = store.getState().Sequencer.tracks.findIndex(ele => ele.id === trackId);
		var trackPartRef = store.getState().Sequencer.tracks[trackNum].part
		const loopEnd = Tone.Time(trackPartRef.loopEnd).toBarsBeatsSixteenths()
		const loopStart = Tone.Time(trackPartRef.loopStart).toBarsBeatsSixteenths();

			console.log('start: ', loopStart);
			console.log('length: ', loopEnd);


		// find scene
			const activeSceneId = store.getState().SongState.activeSceneId;
			var sceneData = store.getState().SongData.scenes.find((ele) => ele.id === activeSceneId)

		// find track 
			var trackToUpdate = sceneData.tracks.find((ele) => ele.id === trackId)
			var trackNum = sceneData.tracks.findIndex((ele) => ele.id === trackId)
		
		// add notes to track	
			if (!trackToUpdate) {
				trackToUpdate = {id: trackId, notes: [note], loopstart: loopStart, looplength: loopEnd}
				sceneData.tracks.push(trackToUpdate)
			} else {
				trackToUpdate.notes.push(note)
				sceneData.tracks[trackNum] = trackToUpdate
			}

		// send to db
			db.collection('songs').doc('ZaDy9Hzhzch2Z11hueni').collection('scenes').doc(activeSceneId).set({
				'tracks': sceneData.tracks
			})
	},

	addTrack: (trackId) => {
		db.collection('songs').doc('ZaDy9Hzhzch2Z11hueni').collection('tracks').doc(trackId.toString()).set({mute: false})
	},
	
	removeTrack: (trackId) => {
		// remove track
			db.collection('songs').doc('ZaDy9Hzhzch2Z11hueni').collection('tracks').doc(trackId).delete()

		// remove tracknotes from active scene - ALL SCENES MISSING!!!
			const activeSceneId = store.getState().SongState.activeSceneId;
			var sceneDataTracks = store.getState().SongData.scenes.find((ele) => ele.id === activeSceneId).tracks
			var updatedSceneTracks = sceneDataTracks.filter((ele) => ele.id !== trackId)


			console.log(updatedSceneTracks);


			db.collection('songs').doc('ZaDy9Hzhzch2Z11hueni').collection('scenes').doc(activeSceneId).set({
				'tracks': updatedSceneTracks
			})


	},

	updateTrack: (trackId) => {
		var trackData = store.getState().SongData.tracks.find(ele => ele.id == trackId).data;
		db.collection('songs').doc('ZaDy9Hzhzch2Z11hueni').collection('tracks').doc(trackId.toString()).set(trackData)
	},

	toggleMuteTrack: (trackId, muteVal) => {
		db.collection('songs').doc('ZaDy9Hzhzch2Z11hueni').collection('tracks').doc(trackId).update({
				'mute': muteVal
			})
	},

	clearAllTrackNotes: (trackId) => {
		console.log('clearing all tracknotes', trackId);

		// find scene
			const activeSceneId = store.getState().SongState.activeSceneId;
			var sceneData = store.getState().SongData.scenes.find((ele) => ele.id === activeSceneId)

		// find track 
			var trackToUpdate = sceneData.tracks.find((ele) => ele.id == trackId)
		
		// add notes to track	
			if (!trackToUpdate) {
				trackToUpdate = {id: trackId, notes: [], loopstart: '0:0:0', looplength: '0:1:0'}
				sceneData.tracks.push(trackToUpdate)
			} else {
				var trackNum = sceneData.tracks.findIndex((ele) => ele.id == trackId)
				trackToUpdate.notes = []
				sceneData.tracks[trackNum] = trackToUpdate
			}

			console.log(sceneData.tracks);

		// send to db
			db.collection('songs').doc('ZaDy9Hzhzch2Z11hueni').collection('scenes').doc(activeSceneId).set({
				'tracks': sceneData.tracks
			})
	},

	updateLoopLengthOnTrack: (trackId) => {
		var trackNum = store.getState().Sequencer.tracks.findIndex(ele => ele.id === trackId);
		var trackPartRef = store.getState().Sequencer.tracks[trackNum].part
		const loopEnd = Tone.Time(trackPartRef.loopEnd).toBarsBeatsSixteenths()
		const loopStart = Tone.Time(trackPartRef.loopStart).toBarsBeatsSixteenths();

			console.log('start: ', loopStart);
			console.log('length: ', loopEnd);

		// find scene
			const activeSceneId = store.getState().SongState.activeSceneId;
			var sceneData = store.getState().SongData.scenes.find((ele) => ele.id === activeSceneId)

		// find track 
			var trackToUpdate = sceneData.tracks.find((ele) => ele.id == trackId)
			var trackNum = sceneData.tracks.findIndex((ele) => ele.id == trackId)
		
		// add notes to track	
			if (!trackToUpdate) {
				trackToUpdate = {id: trackId, notes: [], loopstart: loopStart, looplength: loopEnd}
				sceneData.tracks.push(trackToUpdate)
			} else {
				trackToUpdate.loopstart = loopStart
				trackToUpdate.looplength = loopEnd
				sceneData.tracks[trackNum] = trackToUpdate
			}

		// send to db
			db.collection('songs').doc('ZaDy9Hzhzch2Z11hueni').collection('scenes').doc(activeSceneId).set({
				'tracks': sceneData.tracks
			})
	},

	setBpm: (val) => {
		db.collection('songs').doc('ZaDy9Hzhzch2Z11hueni').set({
			'bpm': val
		})
	}
}

export default dbHandler