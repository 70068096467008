import {store} from './../Store'

export const addNoteToPressedNotesArray = (note) => {
	store.dispatch({
	  type: 'ADD_NOTE_TO_PRESSED_NOTES',
	  note: note
	})
}

export const removeNoteFromPressedNotesArray = (note) => {
	store.dispatch({
	  type: 'REMOVE_NOTE_FROM_PRESSED_NOTES',
	  note: note
	})
}
