import soundHandler from './soundHandler'
import visualsHandler from './visualsHandler'

var sequenceHandler = {
	addTrack: (data) => {
		visualsHandler.addTrack(data);
		soundHandler.addTrack(data);
	},

	addNoteToTrack: (trackId, note) => {
		soundHandler.addNoteToTrack(trackId, note)
		visualsHandler.addNoteToTrack(trackId, note)
	},

	removeTrack: (trackId) => {
		visualsHandler.removeTrack(trackId)
		
		soundHandler.clearAllTrackNotes(trackId);
		soundHandler.removeTrack(trackId)
	},

	toggleTransport: () => {
		soundHandler.toggleTransport()
		visualsHandler.toggleTransport();
	},

	increaseLoopLengthOnTrack: (trackId) => {
		soundHandler.increaseLoopLengthOnTrack(trackId);
		visualsHandler.updateNotePositions(trackId);
	},

	decreaseLoopLengthOnTrack: (trackId) => {
		soundHandler.decreaseLoopLengthOnTrack(trackId);
		visualsHandler.updateNotePositions(trackId);
	},

	setLoopLengthOnTrack: (trackId, length) => {
		soundHandler.setLoopLengthOnTrack(trackId, length);
		visualsHandler.updateNotePositions(trackId);
	},

	setTrackLoopLengthAndStart: (trackId, loopstart, looplength) => {
		soundHandler.setTrackLoopLengthAndStart(trackId, loopstart, looplength)
	},

	clearAllTrackNotes: (trackId) => {
		visualsHandler.clearAllTrackNotes(trackId);
		soundHandler.clearAllTrackNotes(trackId);
	},

	setTrackPlaybackRate: (trackId, playbackRate) => {
		soundHandler.setTrackPlaybackRate(trackId, playbackRate);
	}
}

export default sequenceHandler