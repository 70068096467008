// import {arrayFilledWithNotes} from './../Helpers'

const initialState = {
	initiated: false,
	inputs: [],
	outputs: [],
	currentOutput: null,
	currentNotesPressed: [],
}

const Midi = (state = initialState, action) => {
	switch(action.type) {

		case 'INIT_MIDI':

			return {
				...state,
				initiated: true,
				inputs: action.inputs,
				outputs: action.outputs,
				currentOutput: action.currentOutput,
				currentNoteInput: action.currentNoteInput
			}

		break;

		case 'ADD_NOTE_TO_PRESSED_NOTES':
			var notes = state.currentNotesPressed;
			notes.push(action.note);
			return {
				...state,
				currentNotesPressed: notes
			}
		break;

		case 'REMOVE_NOTE_FROM_PRESSED_NOTES':
			var index = state.currentNotesPressed.indexOf(action.note)

			return {
				...state,
				currentNotesPressed: state.currentNotesPressed.filter((item, id) => id !== index)
			}
		break;

		default:
			return state
	}
}

export default Midi