import paper from 'paper';

import {store} from './../State/Store'
import {VisualsTrack} from './VisualsTrack'

export function VisualsRenderer() {
	var tool;
	var selectedTrack = null;
	var tracks = new Array();
	var mousePos = new paper.Point(window.innerWidth/2, window.innerHeight/2)

	this.init = () => {
		window.onload = () => {
			console.log('initializing sequencer');

			paper.setup('visuals');
			tool = new paper.Tool();

			// record indicator
				this.record = new paper.Shape.Circle(new paper.Point(window.innerWidth/2,window.innerHeight/2), 230);
				this.record.strokeColor = '#f99e9e'
				this.record.strokeWidth = 6
				this.record.visible = false;

			paper.view.onFrame = (event) => {
				for (var i = 0; i < tracks.length; i++) {
					tracks[i].update();
				}
			}

			tool.onMouseDown = (event) => {
				// console.log(event);
				// this.addTrack(event.point);
			}

			tool.onMouseMove = (event) => {
				this.mousePos = event.point;
			}

			tool.onKeyDown = (event) => {
				// console.log(event.key);
				switch(event.key) {
					case 'space':
						// this.toggleTracksAreRunning()
					break;

					case '-':
						paper.view.zoom += 0.1
					break;

					case '.':
						paper.view.zoom -= 0.1
					break;

					case ',':
						paper.view.center = this.mousePos;
					break;

					case 'n':
					paper.project.activeLayer.fitBound(paper.view.bounds)
						console.log(paper.view.bounds);
					break;

					// case '2':
					// 	tracks[0].playRandomNote()
					// break;

					// case '3':
					// 	paper.view.zoom = 2
					// 	paper.view.center = new paper.Point(window.innerWidth/4, window.innerHeight/4)
					// break;

					// case '4':
					// 	paper.view.zoom = 1
					// 	paper.view.center = new paper.Point(window.innerWidth/2, window.innerHeight/2)
					// break;

					// case 'q':
					// 	tracks[0].playRandomNote()
					// break;

					// case 'w':
					// 	tracks[1].playRandomNote()
					// break;

					// case 'e':
					// 	tracks[2].playRandomNote()
					// break;

					// case 'r':
					// 	tracks[3].playRandomNote()
					// break;

					// case 'a':
					// 	tracks[0].addRandomNote()
					// break;

					// case 's':
					// 	tracks[1].addRandomNote()
					// break;

					// case 'd':
					// 	tracks[2].addRandomNote()
					// break;

					// case 'f':
					// 	tracks[3].addRandomNote()
					// break;
				}
			}

			// this.createTestTracks(4);

			// tracks = new paper.Path.Circle(new paper.Point(200,200),100);
			// tracks.strokeColor = 'black';
		}
	}

	this.createTestTracks = (num) => {
		// for (var i = 0; i < num; i++) {
		// 	var pos = paper.Point.random().multiply(new paper.Point(window.innerWidth, window.innerHeight));
		// 	pos.x = window.innerWidth/2
		// 	pos.y = window.innerHeight/2
		// 	this.addTrack(pos)
		// }
		
		this.addTrack(new paper.Point(window.innerWidth/4, window.innerHeight/4))
		this.addTrack(new paper.Point(window.innerWidth/4*3, window.innerHeight/4))
		this.addTrack(new paper.Point(window.innerWidth/4, window.innerHeight/4*3))
		this.addTrack(new paper.Point(window.innerWidth/4*3, window.innerHeight/4*3))
		
	}

	this.addTrack = (id, mute) => {
		console.log('add track mute', mute);
		console.log(id, mute);
		var pos = new paper.Point(Math.random()*window.innerWidth, Math.random()*window.innerHeight);
		var track = new VisualsTrack(id, pos, mute)
		tracks.push(track)
		this.arrangeTrackPositions()
	}

	this.removeTrack = (trackId) => {
		console.log('remove track', trackId);
		var trackNum = tracks.findIndex(ele => ele.trackId === trackId)
		tracks[trackNum].removeTrack();
		console.log(trackNum);
		tracks.splice(trackNum, 1)
		console.log(tracks.length);
		this.arrangeTrackPositions()
	}

	this.arrangeTrackPositions = () => {
		const rows = Math.floor(Math.sqrt(tracks.length));
		const maxColumns = Math.ceil(tracks.length/rows)
		// console.log('length', tracks.length);
		// console.log('rows', rows);
		// console.log('maxColumns', maxColumns);

		for (let i = 0 ; i < tracks.length; i++) {
			const currentRow = Math.floor(i/maxColumns)
			const currentCol = i%maxColumns;
			// console.log('current row:', currentRow, ' - current col:', currentCol);
			tracks[i].setNewPosition(currentCol * 500 + 300, currentRow * 500 + window.innerHeight/2)
		}
	}

	this.activateTrack = (trackId) => {
		var track = tracks.find(ele => ele.trackId === trackId)
		track.activate();

		this.updateArmTrack();
	}

	this.deactivateAllTracks = () => {
		for (let i = 0 ; i < tracks.length; i++) {
			tracks[i].deactivate();
		}

		this.updateArmTrack();
	}

	this.selectNextTrack = () => {
		for (var i = 0; i < tracks.length; i++) {
			tracks[i].deselect();
		}

		if (selectedTrack == null) selectedTrack = 0; else selectedTrack++;
		if (selectedTrack >= tracks.length) selectedTrack = 0;
		tracks[selectedTrack].select();
	}

	this.toggleTracksAreRunning = () => {
		for (var i = 0; i < tracks.length; i++) {
			tracks[i].toggleTrackIsRunning();
		}
	}

	this.playNote = (trackId, noteId) => {
		var trackNum = tracks.findIndex(ele => ele.trackId === trackId)
		tracks[trackNum].playNote(noteId)
	}

	this.addNote = (trackId, note) => {
		// console.log(trackId, note);
		// console.log(tracks);
		
		var track = tracks.find(ele => ele.trackId === trackId)
		track.addNote(note)
	}

	this.updateNotePositions = (trackId) => {
		var trackNum = tracks.findIndex(ele => ele.trackId === trackId)
		tracks[trackNum].updateNotePositions()
	}

	this.toggleMuteTrack = (trackId) => {
		var trackNum = tracks.findIndex(ele => ele.trackId === trackId)
		tracks[trackNum].toggleMuteTrack()	
	}

	this.clearAllTrackNotes = (trackId) => {
		var trackNum = tracks.findIndex(ele => ele.trackId === trackId)
		console.log('HEP', trackId, trackNum);
		tracks[trackNum].clearAllNotes()		
	}

	this.updateArmTrack = () => {
		var trackArmed = store.getState().SongState.trackRecordArmed
		console.log('track armed', trackArmed);
		this.record.visible = trackArmed
		
		if (trackArmed) {
			this.record.bringToFront();
			var trackId = store.getState().SongState.activeTrackId;
			var trackNum = tracks.findIndex(ele => ele.trackId === trackId)
			this.record.position = tracks[trackNum].pos;;
		}
	}

	this.init();
}