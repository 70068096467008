import React, {useEffect, useRef} from 'react';
import {connect} from 'react-redux'
import './App.scss';

import Song from './js/Components/Song'
import Visuals from './js/Components/Visuals'
import Settings from './js/Components/Settings'
import QuantizeSize from './js/Components/QuantizeSize'
import Tempo from './js/Components/Tempo'

import {addNoteToActiveScene} from './js/State/Actions/sceneActions'
import {keyHandler} from './js/State/Handlers/keyHandler'

function App(props) {

  const appRef = useRef();

  useEffect(() => {
  	appRef.current.focus()
  }, [])

  return (
    <div id="App" ref={appRef} tabIndex="0" onKeyDown={(e) => keyHandler(e, 'down')} onKeyUp={(e) => keyHandler(e, 'up')}>
      <Tempo/>
      <QuantizeSize/>
      <Song/>
      <Visuals/>
      <Settings/>
    </div>
  );
}

export default connect()(App);
