import {store} from './../Store'
import sequenceHandler from './../Handlers/sequenceHandler.js'
import dbHandler from './../Handlers/dbHandler.js'
import Tone from 'tone'
import {getTrackLoopLength} from './../../Helpers'

export const addNoteToTrack = (trackId, notePos, noteVal) => {
	// console.log('adding note to track', trackId, notePos, noteVal);

	const note = {
		id: Math.floor(Math.random()*10000000),
		pos: notePos,
		val: noteVal
	}
 // add note to sequence
		sequenceHandler.addNoteToTrack(trackId, note)

	// add note to db
		dbHandler.addNoteToTrack(trackId, note)
	

	// add note to scene reducer
}

export const addNoteToTrackAtTrackPos = (noteVal) => {
	const transport = store.getState().Sequencer.transport;
	var trackId = store.getState().SongState.activeTrackId;
	var latencyCompensation = transport.state === 'started' ? store.getState().Sequencer.latencyCompensationInSeconds : 0;
	
	var trackPart = store.getState().Sequencer.tracks.find(ele => ele.id === trackId).part;
	console.log(trackPart);
	var notePosInLoopVal = trackPart.progress * getTrackLoopLength(trackId) - latencyCompensation;
	var notePosInLoop = notePosInLoopVal >= 0 ? notePosInLoopVal : 0;
	var noteTimeInLoop = Tone.TransportTime(notePosInLoop).toBarsBeatsSixteenths();

	console.log(notePosInLoop, noteTimeInLoop);

	// var notePos = transport.seconds - latencyCompensation
	// notePos = notePos < 0 ? 0 : notePos;
	if (trackId) addNoteToTrack(trackId, noteTimeInLoop, noteVal)
}

export const addNoteToTrackAtTransportPos  = (noteVal) => {
	console.log('adding note at transport pos');
	const transport = store.getState().Sequencer.transport;
	var trackId = store.getState().SongState.activeTrackId;
	
	var trackPart = store.getState().Sequencer.tracks.find(ele => ele.id === trackId).part;




	var notePosInLoop = transport.seconds%getTrackLoopLength(trackId);
	console.log(notePosInLoop);
	var noteTimeInLoop = Tone.TransportTime(notePosInLoop).toBarsBeatsSixteenths();

	console.log('track length', getTrackLoopLength(trackId));
	console.log('transport pos', transport.seconds);
	console.log('transport pos in track', transport.seconds%getTrackLoopLength(trackId));
	// console.log(notePosInLoop, noteTimeInLoop);

	// var notePos = transport.seconds - latencyCompensation
	// notePos = notePos < 0 ? 0 : notePos;
	
	if (trackId) addNoteToTrack(trackId, noteTimeInLoop, noteVal)
}