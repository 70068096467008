import {store} from './../Store'
import dbHandler from './../Handlers/dbHandler.js'

export const gotoNextTransportStep = () => {
	store.dispatch({type: 'GOTO_NEXT_TRANSPORT_STEP'})	
}

export const gotoPrevTransportStep = () => {
	store.dispatch({type: 'GOTO_PREV_TRANSPORT_STEP'})	
}

export const increaseQuantizeSize = () => {
	store.dispatch({type: 'INCREASE_QUANTIZE_SIZE'})	
}

export const decreaseQuantizeSize = () => {
	store.dispatch({type: 'DECREASE_QUANTIZE_SIZE'})	
}

export const setQuantizeSizeNormalized = (val) => {
	store.dispatch({type: 'SET_QUANTIZE_SIZE_NORMALIZED', val: val})
}

export const setTempo = (bpm) => {
	const bpmRound = Math.round(bpm) 
	dbHandler.setBpm(bpmRound);
    store.dispatch({
    	type: 'SET_TEMPO',
    	bpm: bpmRound
	})
}