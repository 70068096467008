import dbHandler from './../Handlers/dbHandler.js'
import sequenceHandler from './../Handlers/sequenceHandler.js'
import visualsHandler from './../Handlers/visualsHandler.js'
import {store} from './../Store'

export const addTrack = () => {
	const trackId = Math.floor(Math.random()*10000000000000).toString();
	// add track to db
		dbHandler.addTrack(trackId);

	// add to SongState
		store.dispatch({
			type: 'ADD_TRACK',
			trackId: trackId,
			data: {mute: false}
		})

	// add track to sequence
		sequenceHandler.addTrack({id: trackId, data: {mute: false}});
		
}

export const deleteSelectedTrack = () => {
    const activeTrackId = store.getState().SongState.activeTrackId
    if (activeTrackId) {
    	deleteTrack(activeTrackId)
    	activateTrackNextPrev('next');
    }
}

export const deleteTrack = (trackId) => {
	// remove track from db
		dbHandler.removeTrack(trackId)

	// remove from songstate
		store.dispatch({
			type: 'REMOVE_TRACK',
			trackId: trackId,
		})
		
	// remove track from sequence
		sequenceHandler.removeTrack(trackId)
}

export const activateTrackNextPrev = (nextPrev) => {
	const currentSelectedTrackId = store.getState().SongState.activeTrackId;
	const tracks = store.getState().SongData.tracks;
	let nextTrackId;
	console.log(tracks);
	if (tracks.length == 0) {
		deactivateAllTracks();
	} else if (!currentSelectedTrackId) {
		nextTrackId = tracks[0].id
	} else {
		let currentTrackNum = tracks.findIndex(ele => ele.id === currentSelectedTrackId)
		if (nextPrev == 'next') nextTrackId = currentTrackNum === tracks.length-1 ? tracks[0].id : tracks[currentTrackNum+1].id
		else if (nextPrev == 'prev') nextTrackId = currentTrackNum === 0 ? tracks[tracks.length-1].id : tracks[currentTrackNum-1].id
	}

	activateTrack(nextTrackId);
}

export const setTrackPlaybackRate = (playBackRate) => {
	const trackId = store.getState().SongState.activeTrackId;
	if (trackId) {
		sequenceHandler.setTrackPlaybackRate(trackId, playBackRate)
	}
}

export const deactivateAllTracks = () => {
	visualsHandler.deactivateAllTracks();
	store.dispatch({type: 'SET_ACTIVE_TRACK', trackId: null})
}

export const activateTrack = (trackId) => {
	store.dispatch({type: 'SET_ACTIVE_TRACK', trackId: trackId})

	visualsHandler.deactivateAllTracks();
	visualsHandler.activateTrack(trackId);
}

export const activateTrackToggle = (trackId) => {
	const currentActiveTrackId = store.getState().SongState.activeTrackId;

	if (trackId === currentActiveTrackId) {
		visualsHandler.deactivateAllTracks();
		store.dispatch({type: 'SET_ACTIVE_TRACK', trackId: null})
	} else {
		store.dispatch({type: 'SET_ACTIVE_TRACK', trackId: trackId})
		visualsHandler.deactivateAllTracks();
		visualsHandler.activateTrack(trackId);		
	}
}

export const setMidiOutput = (trackId, midiOutput) => {
	store.dispatch({
		type: 'SET_TRACK_MIDI_OUTPUT',
		trackId: trackId,
		midiOutput: midiOutput
	})

	dbHandler.updateTrack(trackId);
}

export const setMidiChannel = (trackId, midiChannel) => {
	store.dispatch({
		type: 'SET_TRACK_MIDI_CHANNEL',
		trackId: trackId,
		midiChannel: midiChannel
	})

	dbHandler.updateTrack(trackId);
}

export const increaseLoopLengthOnSelectedChannel = () => {
	const trackId = store.getState().SongState.activeTrackId

	if (trackId) {
		sequenceHandler.increaseLoopLengthOnTrack(trackId)
		dbHandler.updateLoopLengthOnTrack(trackId)
	}
}

export const decreaseLoopLengthOnSelectedChannel = () => {
	const trackId = store.getState().SongState.activeTrackId

	if (trackId) {
		sequenceHandler.decreaseLoopLengthOnTrack(trackId)
		dbHandler.updateLoopLengthOnTrack(trackId)
	}
}

export const setLoopLengthOnSelectedTrack = (length) => {
	const trackId = store.getState().SongState.activeTrackId

	if (trackId) {
		sequenceHandler.setLoopLengthOnTrack(trackId, length)
		dbHandler.updateLoopLengthOnTrack(trackId)
	}	
}

export const toggleMuteSelectedTrack = () => {
	const trackId = store.getState().SongState.activeTrackId
	if (trackId) {
		store.dispatch({
			type: 'TOGGLE_MUTE_TRACK',
			trackId: trackId
		})

		var muteVal = store.getState().SongData.tracks.find(ele => ele.id == trackId).data.mute

		visualsHandler.toggleMuteTrack(trackId)
		dbHandler.toggleMuteTrack(trackId, muteVal);
	}
}

export const clearAllTrackNotes = (trackId) => {
	dbHandler.clearAllTrackNotes(trackId)
	sequenceHandler.clearAllTrackNotes(trackId)
}

export const clearAllTrackNotesOnSelectedTrack = () => {
	const trackId = store.getState().SongState.activeTrackId;
	clearAllTrackNotes(trackId)
}

export const toggleArmTrack = () => {
	if (store.getState().SongState.activeTrackId) store.dispatch({type: 'TOGGLE_ARM_TRACK'})
	visualsHandler.updateArmTrack()
}