import Tone from 'tone'
import {store} from './../State/Store'

export const calculateOffset = (time) => {
        var newTimingOffset = (time - Tone.immediate()) * 1000;
        var newTimingOffsetString = '+'+newTimingOffset.toString();
        return newTimingOffsetString;
    }

export const calculateNormalizedNotePositionInLoop = (notePos, trackId) => {
	var track = store.getState().Sequencer.tracks.find(ele => ele.id === trackId);
	var loopLength = track.part.loopEnd - track.part.loopStart
	var notePosition = Tone.TransportTime(notePos).toSeconds()
	var normalizedPos = notePosition/loopLength;
	return normalizedPos;
}

export const getTrackLoopLength = (trackId) => {
	var track = store.getState().Sequencer.tracks.find(ele => ele.id === trackId);
	var loopLength = track.part.loopEnd - track.part.loopStart
	return loopLength;
}

export const getTrackLoopLengthInBars = (trackId) => {
	var track = store.getState().Sequencer.tracks.find(ele => ele.id === trackId);
	var loopLength = track.part.loopEnd - track.part.loopStart
	var loopLengthInBars = Tone.TransportTime(loopLength).toBarsBeatsSixteenths();
	return loopLengthInBars;	
}