import React, {useEffect} from 'react';

import { connect } from 'react-redux'
import './index.scss';

import {setMidiOutput, setMidiChannel} from './../../State/Actions/trackActions'

function Settings(props) {
	useEffect(() => {
		console.log(props.activeTrack);
	}, [props.activeTrack])

	const midiChannels = () => {
		var channels = new Array();
		for (var i = 1; i < 17; i++) {
			channels.push(
				<li key={i} className={props.activeTrack.data.midiChannel == i ? 'active' : ''} onClick={() => setMidiChannel(i)}>{i}</li>
				)
		}
		return channels;
	}	

	const setMidiOutputClick = (ele) => {
		setMidiOutput(props.activeTrackId, ele)
	}

	const setMidiChannelClick = (ele) => {
		console.log(ele);
		setMidiChannel(props.activeTrackId, ele)
	}

	return (
		<div id="settings" className={props.open ? 'open' : ''}>
			{props.activeTrack ?
				<div>
					<div className="single-setting midi-output">
						<h4>OUTPUT</h4>
						<ul>
						{props.midiOutputs.map((ele) => (
								<li key={ele.id} className={props.activeTrack.data.midiOutput == ele.id ? 'active' : ''} onClick={() => setMidiOutputClick(ele.id)}>{ele.name}</li>
							))}
						</ul>
					</div>
					<div className="single-setting midi-channel">
						<h4>CHANNEL</h4>
						<ul className="midi-output">
							<li key={1} className={props.activeTrack.data.midiChannel == 1 ? 'active' : ''} onClick={() => setMidiChannelClick(1)}>{1}</li>
							<li key={2} className={props.activeTrack.data.midiChannel == 2 ? 'active' : ''} onClick={() => setMidiChannelClick(2)}>{2}</li>
							<li key={3} className={props.activeTrack.data.midiChannel == 3 ? 'active' : ''} onClick={() => setMidiChannelClick(3)}>{3}</li>
							<li key={4} className={props.activeTrack.data.midiChannel == 4 ? 'active' : ''} onClick={() => setMidiChannelClick(4)}>{4}</li>
							<li key={5} className={props.activeTrack.data.midiChannel == 5 ? 'active' : ''} onClick={() => setMidiChannelClick(5)}>{5}</li>
								
						</ul>
					</div>
				</div>
			: <div className="message">No track selected</div>}
		</div>
	)
}

export default connect((state) => ({
	open: state.Settings.open,
	midiOutputs: state.Midi.outputs,
	activeTrack: state.SongData.tracks ? state.SongData.tracks.find(ele => ele.id === state.SongState.activeTrackId) : null,
	activeTrackId: state.SongState.activeTrackId
}))(Settings);