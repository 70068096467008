import Tone from 'tone';
import _ from 'lodash';

import visualsHandler from './../Handlers/visualsHandler'
import soundHandler from './../Handlers/soundHandler'

const initialState = {
	transport: Tone.Transport,
  	tracks: new Array(),
	latencyCompensationInSeconds: 0.084,
	quantizeStepsizes: ['1n', '2n', '4n', '8n', '16n', '32n'],
	quantizeCurrentStepsize: 3,
	tempo: 120
}

const Sequencer = (state = initialState, action) => {
	switch(action.type) {

		case 'INIT_TRANSPORT':
				state.transport.bpm.value = initialState.tempo
			return {
				...state
			}
		break;

		case 'TOGGLE_TRANSPORT':
			state.transport.toggle();
			if (state.transport.state == 'started') state.tracks.forEach(ele => ele.part.start())
			else state.tracks.forEach(ele => ele.part.stop())

			return {
				...state
			}
		break;

		case 'ADD_NOTE_TO_SEQUENCER_TRACK':
		// console.log('adding note to sequencer');

			var trackNum = state.tracks.findIndex(ele => ele.id === action.trackId)
			state.tracks[trackNum].part.add(action.time, {noteVal: action.noteVal, trackId: action.trackId, noteId: action.noteId})

			return {
				...state
			}
		break;

		case 'ADD_TRACK_TO_SEQUENCER':
			console.log(action.data.id);
			
			var newTrack = {
				id: action.data.id,
				part: new Tone.Part((time, value) => {
			  			soundHandler.playMidiNote(value.noteVal, time, value.trackId)
						visualsHandler.playNote(value.trackId, value.noteId)
			  	})
			}

			newTrack.part.loop = true;
			newTrack.part.loopEnd = '1:0:0';

			console.log(newTrack);

			// var tracks = state.tracks;
			// tracks.push(newTrack)
			
			return {
				...state,
				tracks: [...state.tracks, newTrack]
			}
		break;

		case 'REOVE_TRACK_FROM_SEQUENCE':
			return {
				...state,
				tracks: state.tracks.filter(ele => ele.id !== action.trackId)
			}
		break;

		case 'CLEAR_ALL_TRACK_NOTES':
			var trackNum = state.tracks.findIndex(ele => ele.id === action.trackId)
			state.tracks[trackNum].part.removeAll();

			return state
		break;

		case 'SET_PLAYBACK_RATE':
			var trackNum = state.tracks.findIndex(ele => ele.id === action.trackId)
			console.log('playbackRate', action.playbackRate);
			state.tracks[trackNum].part.playbackRate = action.playbackRate;
			return state;
		break;

		case 'SET_TRACK_LOOP_START_AND_LENGTH':
			console.log(action);
			var trackNum = state.tracks.findIndex(ele => ele.id === action.trackId);
			state.tracks[trackNum].part.loopEnd = action.looplength;
			state.tracks[trackNum].part.loopStart = action.loopstart;

			return state;
		break;

		case 'INCREASE_LOOP_LENGTH_ON_SEQUENCE_TRACK':
			var trackNum = state.tracks.findIndex(ele => ele.id === action.trackId)
			var newLoopLength = state.tracks[trackNum].part.loopEnd + Tone.Time('0:1:0').toSeconds();
			state.tracks[trackNum].part.loopEnd = newLoopLength;

			console.log(state.tracks[trackNum].part.loopEnd);

			return state
		break;

		case 'DECREASE_LOOP_LENGTH_ON_SEQUENCE_TRACK':
			var trackNum = state.tracks.findIndex(ele => ele.id === action.trackId)
			var newLoopLength = state.tracks[trackNum].part.loopEnd - Tone.Time('0:1:0').toSeconds();
			state.tracks[trackNum].part.loopEnd = newLoopLength;

			console.log(state.tracks[trackNum].part.loopEnd);

			return state
		break;

		case 'SET_LOOP_LENGTH_ON_SEQUENCE_TRACK':
			var trackNum = state.tracks.findIndex(ele => ele.id === action.trackId)
			var newLoopEnd = state.tracks[trackNum].part.loopStart + Tone.Time(action.length).toSeconds();
			state.tracks[trackNum].part.loopEnd = newLoopEnd;

			return state;

		break;

		case 'GOTO_NEXT_TRANSPORT_STEP':
			var currentTransportPos = state.transport.position;
	        var stepSize = state.quantizeStepsizes[state.quantizeCurrentStepsize];

	        var newTransportPos = Tone.TimeBase(currentTransportPos) + Tone.TimeBase(stepSize);
	        state.transport.seconds = newTransportPos;

	       	console.log(state.transport.seconds);
	       	console.log(state.tracks[0].part.progress);

	        return state
		break;

		case 'GOTO_PREV_TRANSPORT_STEP':
			var currentTransportPos = state.transport.position;
	        var stepSize = state.quantizeStepsizes[state.quantizeCurrentStepsize];

	        var newTransportPos = Tone.TimeBase(currentTransportPos) - Tone.TimeBase(stepSize);
	        state.transport.seconds = newTransportPos < 0 ? 0 : newTransportPos;

	        return state
		break;

		case 'INCREASE_QUANTIZE_SIZE':
			var quantizeStep = state.quantizeCurrentStepsize + 1;
			if (quantizeStep >= state.quantizeStepsizes.length) quantizeStep = 0;

	        var stepSize = state.quantizeStepsizes[state.quantizeCurrentStepsize];

			return {
				...state,
				quantizeCurrentStepsize: quantizeStep
			}
		break;

		case 'DECREASE_QUANTIZE_SIZE':
			var quantizeStep = state.quantizeCurrentStepsize - 1;
			if (quantizeStep <= -1) quantizeStep = state.quantizeStepsizes.length-1;

	        var stepSize = state.quantizeStepsizes[state.quantizeCurrentStepsize];

			return {
				...state,
				quantizeCurrentStepsize: quantizeStep
			}
		break;

		case 'SET_QUANTIZE_SIZE_NORMALIZED':
			var quantizeStep = Math.round(action.val*(state.quantizeStepsizes.length-1));

			return {
				...state,
				quantizeCurrentStepsize: quantizeStep
			}
		break;

		case 'SET_TEMPO':
            state.transport.bpm.value = action.bpm;

			return {
				...state,
				tempo: action.bpm
			}

		break;

		default:
			return state
	}
}

export default Sequencer