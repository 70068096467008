const initialState = {
	trackRecordArmed: false
}

const SongState = (state = initialState, action) => {
	switch(action.type) {

		case 'SET_ACTIVE_SCENE':
			return {
				...state,
				activeSceneId: action.sceneId
			}
		break;

		case 'SET_ACTIVE_TRACK':
			return {
				...state,
				activeTrackId: action.trackId
			}
		break;

		case 'TOGGLE_ARM_TRACK':
			return {
				...state,
				trackRecordArmed: !state.trackRecordArmed
			}
		break;

		default:
			return state
	}
}

export default SongState