import _ from 'lodash';
import {VisualsRenderer} from './../../Visuals/Visuals.js'

const initialState = {
	visuals: new VisualsRenderer()
}

const Visuals = (state = initialState, action) => {
	switch(action.type) {

		case 'INIT_VISUALS':
			return {
				...state
			}
		break;

		default:
			return state
	}
}

export default Visuals