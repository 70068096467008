import {store} from './../Store'

var visualsHandler = {
	addTrack: (data) => {
		console.log(data);
		var visuals = store.getState().Visuals.visuals
		visuals.addTrack(data.id, data.data.mute);
	},

	removeTrack: (trackId) => {
		var visuals = store.getState().Visuals.visuals
		visuals.removeTrack(trackId);
	},

	addNoteToTrack: (trackId, note) => {
		var visuals = store.getState().Visuals.visuals
		visuals.addNote(trackId, note)
	},

	playNote: (trackId, noteId) => {
		var visuals = store.getState().Visuals.visuals
		visuals.playNote(trackId, noteId)
	},

	toggleTransport: () => {
		var visuals = store.getState().Visuals.visuals
		visuals.toggleTracksAreRunning();
	},

	deactivateAllTracks: () => {
		var visuals = store.getState().Visuals.visuals
		visuals.deactivateAllTracks();
	},

	activateTrack: (trackId) => {
		var visuals = store.getState().Visuals.visuals
		visuals.activateTrack(trackId)
	},

	updateNotePositions: (trackId) => {
		var visuals = store.getState().Visuals.visuals
		visuals.updateNotePositions(trackId)	
	},

	toggleMuteTrack: (trackId) => {
		var visuals = store.getState().Visuals.visuals
		visuals.toggleMuteTrack(trackId)		
	},

	clearAllTrackNotes: (trackId) => {
		var visuals = store.getState().Visuals.visuals
		visuals.clearAllTrackNotes(trackId);
	},

	updateArmTrack: () => {
		var visuals = store.getState().Visuals.visuals
		visuals.updateArmTrack();	
	}
}

export default visualsHandler