import paper from 'paper'

import {calculateNormalizedNotePositionInLoop} from './../Helpers'

export class VisualsNote {
	constructor(noteData, trackId) {

		this.trackId = trackId
		this.noteTime = noteData.pos
		this.position = calculateNormalizedNotePositionInLoop(noteData.pos, trackId)
		this.noteId = noteData.id
		this.noteVal = noteData.val

		var rad = this.position * Math.PI * 2;
		var xPos = Math.cos(rad) * 200;
		var yPos = Math.sin(rad) * 200;

		this.note = new paper.Shape.Circle({
				center: [xPos,yPos],
				radius: 4,
				fillColor: 'grey',
				blendMode: 'multiply',
				// visible: false
			})

		this.note.onMouseEnter = () => {
			this.note.tweenTo({radius: 20, fillColor: 'black'}, {duration: 100, easing: 'easeOutQuint'})
		}

		this.note.onMouseLeave = () => {
			this.note.tweenTo({radius: 4, fillColor: 'grey'}, {duration: 400, easing: 'easeOutQuint'})
		}

		this.note.onClick = () => {
			console.log('remove note: ', this.noteId);
		}
	}

	play() {
		this.note.tweenTo({radius: 20, fillColor: '#f4a261'}, {duration: 100,easing: 'easeOutQuint'})
		setTimeout(() => {
			this.note.tweenTo({radius: 4, fillColor: '#2a9d8f'}, {duration: 400,easing: 'easeOutQuint'})
		}, 100)
	}

	updateNotePosition() {
		this.position = calculateNormalizedNotePositionInLoop(this.noteTime, this.trackId)

		var rad = this.position * Math.PI * 2;
		var xPos = Math.cos(rad) * 200;
		var yPos = Math.sin(rad) * 200;

		this.note.position = new paper.Point(xPos, yPos)
	}

	removeNote() {
		this.note.remove();
	}
}