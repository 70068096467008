import {combineReducers} from 'redux'
import {firestoreReducer} from 'redux-firestore' // <- needed if using firestore
import {firebaseReducer} from 'react-redux-firebase'

import Sequencer from './Sequencer.js'
import Visuals from './Visuals.js'
import SongData from './SongData.js'
import SongState from './SongState.js'
import Midi from './Midi.js'
import Settings from './Settings.js'

const appReducers = combineReducers({
	Sequencer,
	SongData,
	SongState,
	Visuals,
	Midi,
	Settings
})

export default appReducers