import {store} from './../Store'

import sequenceHandler from './../Handlers/sequenceHandler.js'
import {setActiveSceneTo} from './sceneActions.js'

import dbHandler from './../Handlers/dbHandler.js'

export const InitSong = () => {
	console.log('INITIALIZING SONG');

	// add tracks to sequencer
		for (var i = 0; i < store.getState().SongData.tracks.length; i++) {
			sequenceHandler.addTrack(store.getState().SongData.tracks[i]);
		}

	// load scene 1
		setActiveSceneTo(1)
		
	// delete all notes in track
}