const initialState = {
	open: false
}

const Settings = (state = initialState, action) => {
	switch(action.type) {

		case 'TOGGLE_SETTINGS':

			return {
				...state,
				open: !state.open
			}

		break;

		default:
			return state
	}
}

export default Settings