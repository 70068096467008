import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';

import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { ReactReduxFirebaseProvider } from 'react-redux-firebase'
import {createFirestoreInstance} from 'redux-firestore';
import firebase from 'firebase/app'
import 'firebase/auth'
import  'firebase/database'
import 'firebase/firestore' // <- needed if using firestore

import {store} from './js/State/Store'

var firebaseConfig = {
    apiKey: "AIzaSyCuPORwiMwII1U-tPGJ2DUBxJf8bBfJKdw",
    authDomain: "nora-sequencer.firebaseapp.com",
    databaseURL: "https://nora-sequencer.firebaseio.com",
    projectId: "nora-sequencer",
    storageBucket: "nora-sequencer.appspot.com",
    messagingSenderId: "433607661104",
    appId: "1:433607661104:web:55e3d1678aef0d52703ff5"
  };

firebase.initializeApp(firebaseConfig);
export const db = firebase.firestore();

// var test = db.collection('songs');

// const rrfProps = {
//   firebase,
//   config: {},
//   dispatch: store.dispatch,
//   createFirestoreInstance
// }

// ReactDOM.render(
//   <React.StrictMode>
//     <Provider store={store}>
// 		<ReactReduxFirebaseProvider {...rrfProps}>
// 			<App />
// 		</ReactReduxFirebaseProvider>
// 	</Provider>,
//   </React.StrictMode>,
//   document.getElementById('root')
// );

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
  </Provider>,
  </React.StrictMode>,
  document.getElementById('root')
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
