import paper from 'paper'
import {VisualsNote} from './VisualsNote.js'
import _ from 'lodash'

import {store} from './../State/Store'

import {activateTrackToggle} from './../State/Actions/trackActions';
import {addRandomNoteToTrack} from './../State/Actions/noteActions';
import {getTrackLoopLengthInBars} from './../Helpers'


export class VisualsTrack {
	constructor(id, pos, mute) {

		// define constants
			this.trackTitle = 'test';
			this.shape = new paper.Path();

			this.trackId = id;

			this.tempo = Math.random() + 0.5;

			this.active = false;

			this.timer = null;

			this.muted = mute;
			console.log('contructor is muted', mute);

			this.titleText = 'hej';
			this.trackSize = {
				normal: 200,
				focus: 220
			}

			this.animationOptions = {
					duration: 700,
					easing: 'easeOutQuint'
				}

			this.pos = pos;

			this.running = false;

		// maincircle
			this.circle = new paper.Shape.Circle(new paper.Point(0,0), this.trackSize.focus);
			// this.circle.fillColor = '#ccc¨'
			// this.circle.strokeColor = '#ccc'
			// this.circle.opacity = 0
			// this.circle.blendMode = 'multiply'
			this.circle.strokeWidth = 4
			this.circle.visible = false
		
		// empty indicator
			this.emptyCircle = new paper.Shape.Circle(new paper.Point(0,0), 10)
			this.emptyCircle.fillColor = '#d8d8d8'

			

		// text
			this.title = new paper.PointText({
				point: new paper.Point(0,0),
				content: this.trackTitle,
				fillColor: 'white',
				fontSize: 40,
				justification: 'center',
				visible: false
			})

			this.title.position.y += 4;

		// group
			this.trackGroup = new paper.Group({
				children: [this.circle, this.title, this.emptyCircle],
				position: this.pos,
				applyMatrix: false
			});

		// notes
			this.notes = new Array();
			// this.createTestShape();

		// mouse event listener
			this.trackGroup.onMouseEnter = () => {
				this.circle.fillColor = this.active ? '#d8d8d8' : '#2a9d8f'
			}

			this.trackGroup.onMouseLeave = () => {
				this.circle.fillColor = this.active ? '#d8d8d8' : '#ccc'
			}

			this.trackGroup.onClick = () => {		
				activateTrackToggle(this.trackId)
				// this.zoomToTrack();
				// console.log('clicking track', this.trackId);
				// addRandomNoteToTrack(this.trackId)
			}


	}

	zoomToTrack() {
		console.log(this.pos);
		paper.view.zoom = 2;
		paper.view.center = this.pos
	}

	toggleActivateTrack() {
		if (this.active) this.deactivate();
		else if (!this.active) this.activate();
	}

	activate() {
		this.active = true;
		this.circle.strokeColor = '#dfdfdf'
		this.circle.visible = true;
		// console.log(store.dispatch({
			
		// }));
	}

	deactivate() {
		this.active = false;
		this.circle.visible = false;
		// this.circle.fillColor = '#d1d1d1'
		// this.circle.strokeColor = '#ccc'
	}


	select() {
		// this.circle.fillColor = 'black';
		this.running = false;

		this.shape.tweenTo({
				fillColor: 'blue',
				// radius: this.trackSize.focus
			}, this.animationOptions)

		this.title.tweenTo({
				fillColor: 'white'
			}, this.animationOptions)
	}

	deselect() {
		this.running = true;
		this.shape.tweenTo({
			fillColor: 'grey',
			radius: this.trackSize.normal
		},this.animationOptions)

		this.title.tweenTo({
				fillColor: 'black'
			},this.animationOptions)
	}

	setNewPosition(xPos, yPos) {
		this.pos.x = xPos;
		this.pos.y = yPos;
		// console.log(this.pos);
		this.trackGroup.position = this.pos
	}

	createTestShape() {
		for (var i = 0; i < Math.ceil(Math.random()*8)+20; i++) this.addNote();
	}

	start() {
		this.running = true;
	}

	stop() {
		this.running = false;
	}

	toggleTrackIsRunning() {
		this.running = !this.running;
	}

	addNote(noteData) {
		var note = new VisualsNote(noteData, this.trackId);

		this.notes.push(note)
		this.trackGroup.addChild(note.note)

		this.createShape();

		this.setTrackToNotEmpty();
	}

	removeNote() {
		console.log('remove note');
	}

	removeTrack() {
		this.trackGroup.remove();
	}

	createShape() {
		this.shape.remove();
		this.shape = new paper.Path();
		console.log('is muted', this.muted);
		this.shape.fillColor = this.muted ? '#dfdfdf' : '#d1d1d1'
		
		// this.shape.strokeColor = 'grey'
		this.shape.blendMode = 'multiply'
		this.shape.closed = true;
		this.shape.applyMatrix = false;
		// this.shape.smooth({ type: 'asymmetric' });

		var orderedNotes = _.orderBy(this.notes, ['position'], ['asc'])
		for (var i = 0; i < orderedNotes.length; i++) {
			this.shape.add(orderedNotes[i].note.position);
		}

		this.trackGroup.addChild(this.shape)
	}

	removeShape() {
		this.shape.remove();
	}

	update() {
		if (this.running) this.trackGroup.rotate(this.tempo)
	}

	playNote(noteId) {
		if (!this.muted) {
			var note = this.notes.find(ele => ele.noteId === noteId)
			if (note) {
				note.play();

				// this.shape.tweenTo({fillColor: 'black'}, {duration: 100,easing: 'easeOutQuint'})
				// setTimeout(() => {
				// 	this.shape.tweenTo({fillColor: 'grey'}, {duration: 400,easing: 'easeOutQuint'})
				// }, 100)
			}
		}

	}

	playNoteByIndex(index) {
		this.notes[index].play();

		// this.shape.tweenTo({fillColor: 'black'}, {duration: 100,easing: 'easeOutQuint'})
		// setTimeout(() => {
		// 	this.shape.tweenTo({fillColor: 'd1d1d1'}, {duration: 400,easing: 'easeOutQuint'})
		// }, 100)
	}

	playRandomNote() {
		let noteId = Math.floor(Math.random() * this.notes.length);
		this.playNoteByIndex(noteId)
	}

	setTrackToEmpty() {
		this.emptyCircle.visible = true;
	}

	setTrackToNotEmpty() {
		this.emptyCircle.visible = false;
	}

	updateNotePositions() {
		var trackLengthInBars = getTrackLoopLengthInBars(this.trackId);
		this.title.content = trackLengthInBars;
		this.title.visible = true;

		clearTimeout(this.timer);
		this.timer = setTimeout(() => {
			this.title.visible = false;
		}, 3000)

		for (var i = 0; i < this.notes.length; i++) {
			this.notes[i].updateNotePosition();
		}

		this.createShape();
	}

	toggleMuteTrack() {
		this.muted = !this.muted;
		setTimeout(() => {
			this.shape.tweenTo({fillColor: this.muted ? '#dfdfdf' : '#d1d1d1'}, {duration: 400, easing: 'easeOutQuint'})
		}, 400)
	}

	clearAllNotes() {
		console.log('EOUFKLJ');
		for (var i = 0; i < this.notes.length; i++) {
			this.notes[i].removeNote();
		}
		this.notes = []
		this.removeShape();
		this.setTrackToEmpty();
	}
}