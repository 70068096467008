import Tone from 'tone'
import WebMidi from 'webmidi';
import {store} from './../Store'
import {calculateOffset} from './../../Helpers/'
import visualsHandler from './visualsHandler'
import {addNoteToTrackAtTrackPos, addNoteToTrackAtTransportPos} from './../Actions/noteActions'
import {increaseLoopLengthOnSelectedChannel, decreaseLoopLengthOnSelectedChannel, setTrackPlaybackRate} from './../Actions/trackActions'
import {gotoNextTransportStep, setQuantizeSizeNormalized, setTempo} from './../Actions/transportActions'
import {addNoteToPressedNotesArray, removeNoteFromPressedNotesArray} from './../Actions/midiActions'

var soundHandler = {
	init: (dispatch) => {
		// init transport
			store.dispatch({
				type: 'INIT_TRANSPORT'
			})

		// init midi
		 	WebMidi.enable(function (err) {
			 	if (err) {
		            console.log("WebMidi could not be enabled.", err);
		          } else {
		            console.log("WebMidi enabled!", WebMidi);

		            if (WebMidi.inputs.length > 0 && WebMidi.outputs.length > 0) {

			            let currentOutput = WebMidi.outputs[0],
			                currentNoteInput = WebMidi.inputs[2],
			                currentControllerInput = WebMidi.inputs[3]; 

			            store.dispatch({
			              type: 'INIT_MIDI',
			              inputs: WebMidi.inputs,
			              outputs: WebMidi.outputs,
			              currentOutput: currentOutput,
			              currentNoteInput: currentNoteInput,
			              currentControllerInput: currentControllerInput
			            })

			            currentNoteInput.addListener('noteon', "all", function(e) {
			                var note = e.note.name + e.note.octave;
			                console.log(note);


			                if (store.getState().SongState.activeTrackId) {
			                	if (store.getState().SongState.trackRecordArmed) {
									const transport = store.getState().Sequencer.transport;

									// live recording
				                		if (transport.state === 'started') {
				                			addNoteToTrackAtTrackPos(note)
				                		} else {

			                		// step recording
			                				addNoteToTrackAtTransportPos(note)
			                				addNoteToPressedNotesArray(note)
			                			}
			                	}

				                let currentTrackId = store.getState().SongState.activeTrackId;
				                let trackMidichannelOut = store.getState().SongData.tracks.find(ele => ele.id === currentTrackId).data.midiChannel
				                console.log(trackMidichannelOut);
			                  	currentOutput.playNote(note, trackMidichannelOut, {duration: 20});
			                }


			              })

			            currentNoteInput.addListener('noteoff', "all", (e) => {
			                if (store.getState().SongState.activeTrackId) {
			                	if (store.getState().SongState.trackRecordArmed) {
				                  var note = e.note.name + e.note.octave;
				                  console.log('noteoff', note);

				                  removeNoteFromPressedNotesArray(note)

				                  if (store.getState().Midi.currentNotesPressed.length === 0 && store.getState().Sequencer.transport.state !== 'started') gotoNextTransportStep()
			                	}
			        		}
			            })

			            currentNoteInput.addListener('controlchange', "all",
				              function (e) {
				                switch(e.controller.number) {

				                  // SET QUANTIZATION
					                  case 14:
					                    	var valNormalized = e.value/127;
					                		setQuantizeSizeNormalized(valNormalized)
					                  break;

				                  // SET TEMPO
					                  case 21:
					                    let valNorm = e.value/127;
					                    let valExp = valNorm*valNorm;
					                    let bpmExp = valExp * 260 + 40;

					                    setTempo(bpmExp)
					                  break;

					             	// SET PLAYBACKRATE
					                  case 15:
					                    var val = e.value/127;
					                    var playbackRate = Math.round(val * 8);
					                    console.log(playbackRate);

					                    setTrackPlaybackRate(playbackRate)
					                  break;
				                }
				                console.log("NOTEINPUT : Received 'controlchange' message.", e.controller.number + ' : ' + e.value);
				              }
				            );

			            if (currentControllerInput) currentControllerInput.addListener('controlchange', "all", function (e) {
				                switch(e.controller.number) {
				                	case 60:
				                		if (e.value === 1) increaseLoopLengthOnSelectedChannel()
				                			else if (e.value === 65) decreaseLoopLengthOnSelectedChannel()
				                	break;

				                }
				                console.log("Received 'controlchange' message.", e.controller.number + ' : ' + e.value);
				              });

			            if (currentControllerInput) currentControllerInput.addListener('noteon', "all", function (e) {
				                // console.log("CONTROLLER INPUT: Received 'note' message.", e.note.octave + ' : ' + e.note.name);
				              });

		        	}
		        }
			 })
	}, 

	addTrack: (data) => {
		store.dispatch({
			type: 'ADD_TRACK_TO_SEQUENCER',
			data: data
		})
	},

	removeTrack: (trackId) => {
		store.dispatch({
			type: 'REMOVE_TRACK_FROM_SEQUENCER',
			trackId: trackId
		})
	},

	setTrackLoopLengthAndStart: (trackId, loopstart, looplength) => {
		store.dispatch({
			type: 'SET_TRACK_LOOP_START_AND_LENGTH',
			trackId: trackId,
			loopstart: loopstart,
			looplength: looplength
		})
	},

	addNoteToTrack: (trackId, note) => {
		// console.log(trackId, note);
		const transport = store.getState().Sequencer.transport;
		const visuals = store.getState().Visuals.visuals
		// console.log(transport);

		// transport.start(); // ??????? --- REMOVE THIS ??

		var latencyCompensation = transport.state === 'started' ? store.getState().Sequencer.latencyCompensationInSeconds : 0;
	    // var timeWithoutLatency = transport.seconds - latencyCompensation < 0 ? '0:0:0' : Tone.TransportTime(transport.seconds - latencyCompensation).toBarsBeatsSixteenths();
	    // var timeWithoutLatency = Tone.TransportTime(note.pos).toBarsBeatsSixteenths();
	    // console.log(timeWithoutLatency);

	    // const noteId = Math.round(Math.random()*1000000000);

		// var noteToPlay = new Tone.Event((time) => {
		// 	soundHandler.playMidiNote(note.val, time, trackId)
		// 	visualsHandler.playNote(trackId, note.id)
		// 	console.log('hej');
		// })


		store.dispatch({
			type: 'ADD_NOTE_TO_SEQUENCER_TRACK',
			time: note.pos,
			trackId: trackId,
			noteVal: note.val,
			noteId: note.id
		})

		// noteToPlay.start(timeWithoutLatency)

	},

	playMidiNote: (note, time, trackId) => {
		var trackData = store.getState().SongData.tracks.find((ele) => ele.id == trackId).data;
		if (!trackData.mute) {
			var midiChannel = trackData.midiChannel
			midiChannel = midiChannel ? midiChannel : 1
			var midiOutputId = store.getState().SongData.tracks.find((ele) => ele.id == trackId).data.midiOutput
			var midiOutput = store.getState().Midi.outputs.find(ele => ele.id == midiOutputId);
		  	if (midiOutput) midiOutput.playNote(note, midiChannel, {duration: 20, time: calculateOffset(time)});
		}
	},

	toggleTransport: () => {
		store.dispatch({
            type: 'TOGGLE_TRANSPORT'
        })
	},

	increaseLoopLengthOnTrack: (trackId) => {
		store.dispatch({
			type: 'INCREASE_LOOP_LENGTH_ON_SEQUENCE_TRACK',
			trackId: trackId
		})
	},

	decreaseLoopLengthOnTrack: (trackId) => {
		store.dispatch({
			type: 'DECREASE_LOOP_LENGTH_ON_SEQUENCE_TRACK',
			trackId: trackId
		})
	},

	setLoopLengthOnTrack: (trackId, length) => {
		store.dispatch({
			type: 'SET_LOOP_LENGTH_ON_SEQUENCE_TRACK',
			trackId: trackId,
			length: length
		})
	},


	clearAllTrackNotes: (trackId) => {
		store.dispatch({
			type: 'CLEAR_ALL_TRACK_NOTES',
			trackId: trackId
		})	
	},

	setTrackPlaybackRate: (trackId, playbackRate) => {
		store.dispatch({
			type: 'SET_PLAYBACK_RATE',
			trackId: trackId,
			playbackRate: playbackRate
		})
	}
}

export default soundHandler