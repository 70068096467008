import {store} from './../Store'
import {addTrack, deleteSelectedTrack, setLoopLengthOnSelectedTrack, increaseLoopLengthOnSelectedChannel, decreaseLoopLengthOnSelectedChannel, toggleMuteSelectedTrack, clearAllTrackNotesOnSelectedTrack, toggleArmTrack, activateTrackNextPrev} from './../Actions/trackActions'
import {increaseQuantizeSize, decreaseQuantizeSize} from './../Actions/transportActions'
import {setActiveSceneTo} from './../Actions/sceneActions'
import sequenceHandler from './sequenceHandler'

let shiftIsPreseed = false;
let setLoopLength = false;

export const keyHandler = (e, keyDir) => {
  	console.log(e.keyCode);
   //  console.log(e);

    if (keyDir == 'up') {
        switch(e.keyCode) {
            case 16:
                shiftIsPreseed = false;
            break;
        }
    } else if (keyDir == 'down') {
        if (shiftIsPreseed) {
            switch(e.keyCode) {
                case 49:
                    setLoopLengthOnSelectedTrack('1:0:0')
                break;

                case 50:
                    setLoopLengthOnSelectedTrack('2:0:0')
                break;

                case 51:
                    setLoopLengthOnSelectedTrack('3:0:0')
                break;

                case 52:
                    setLoopLengthOnSelectedTrack('4:0:0')
                break;

                case 53:
                    setLoopLengthOnSelectedTrack('5:0:0')
                break;

                case 54:
                    setLoopLengthOnSelectedTrack('6:0:0')
                break;

                case 55:
                    setLoopLengthOnSelectedTrack('7:0:0')
                break;

                case 56:
                    setLoopLengthOnSelectedTrack('8:0:0')
                break;
            }
        } else {
            switch(e.keyCode) {
            case 8:
                deleteSelectedTrack();
            break;

            case 13:
                store.dispatch({type: 'TOGGLE_SETTINGS'})
            break;

            case 16:
                shiftIsPreseed = true;
            break;

            case 84:
                addTrack();
            break;

            case 49:
                setActiveSceneTo(0)
            break;

            case 50:
                setActiveSceneTo(1)
            break;

            case 32:
                sequenceHandler.toggleTransport();
            break;

            case 66:
                console.log(store.getState().Sequencer.tracks.progress);
            break;

            case 72:
                increaseLoopLengthOnSelectedChannel();
            break;

            case 71:
                decreaseLoopLengthOnSelectedChannel();
            break;

            case 77:
                toggleMuteSelectedTrack();
            break;

            case 67:
                clearAllTrackNotesOnSelectedTrack();
            break;

            case 82:
                toggleArmTrack();
            break;

            case 88:
                increaseQuantizeSize()
            break;

            case 90:
                decreaseQuantizeSize()
            break;

            case 39:
                activateTrackNextPrev('next');
            break;

            case 37:
                activateTrackNextPrev('prev');
            break;
        }
        }
    }
}