const initialState = {
}

const setMidiOutput = (tracks, trackId, midiOutput) => {
	return tracks.map((item) => {
		console.log(item.id, trackId);
		if (item.id !== trackId) return item;
		return {
			...item,
			data: {
				...item.data,
				midiOutput: midiOutput
			}
		}
	})
}

const setMidiChannel = (tracks, trackId, midiChannel) => {
	return tracks.map((item) => {
		console.log(item.id, trackId);
		if (item.id !== trackId) return item;
		return {
			...item,
			data: {
				...item.data,
				midiChannel: midiChannel
			}
		}
	})
}

const toggleMuteTrack = (tracks, trackId) => {
	return tracks.map((item) => {
		if (item.id !== trackId) return item;
		return {
			...item,
			data: {
				...item.data,
				mute: !item.data.mute
			}
		}
	})
}

const SongData = (state = initialState, action) => {
	switch(action.type) {

		case 'SET_SONG':
			return {
				...state,
				song: action.song
			}
		break;

		case 'SET_TRACKS':
			return {
				...state,
				tracks: action.tracks
			}
		break;

		case 'ADD_TRACK':
			console.log(action)
			return {
				...state,
				tracks: [...state.tracks, {id: action.trackId, data: action.data}]
			}
		break;

		case 'REMOVE_TRACK':
			return {
				...state,
				tracks: state.tracks.filter(ele => ele.id !== action.trackId)
			}
		break;

		case 'SET_SCENES':
			return {
				...state,
				scenes: action.scenes
			}
		break;

		case 'SET_TRACK_MIDI_OUTPUT':
			return {
				...state,
				tracks: setMidiOutput(state.tracks, action.trackId, action.midiOutput)
			}
		break;

		case 'SET_TRACK_MIDI_CHANNEL':
			return {
				...state,
				tracks: setMidiChannel(state.tracks, action.trackId, action.midiChannel)
			}
		break;

		case 'TOGGLE_MUTE_TRACK':
			return {
				...state,
				tracks: toggleMuteTrack(state.tracks, action.trackId)
			}
		break;

		default:
			return state
	}
}

export default SongData