import React, {useEffect, useRef} from 'react';

import { compose } from 'redux'
import { connect } from 'react-redux'
import { firestoreConnect, isLoaded, isEmpty } from 'react-redux-firebase'

import {InitSong} from './../../State/Actions/songActions'
import dbHandler from './../../State/Handlers/dbHandler'
import soundHandler from './../../State/Handlers/soundHandler'

import {db} from './../../../index.js'

function Song(props) {
	const initRef = useRef();

	useEffect(() => {
		if (props.songData.song && props.songData.tracks && props.songData.scenes && !initRef.current) {
			InitSong();
			initRef.current = true
		}
	}, [props.songData])

	useEffect(() => {
		dbHandler.init();
		soundHandler.init();
	}, [])

	return null
}

export default connect((state) => ({
	songData: state.SongData
}))(Song);

// export default compose(
// 	firestoreConnect(() => [
// 			{collection: 'songs', doc: 'ZaDy9Hzhzch2Z11hueni', storeAs: 'songTracks', subcollections: [{collection: 'tracks'}]},
// 			{collection: 'songs', doc: 'ZaDy9Hzhzch2Z11hueni', storeAs: 'songScenes', subcollections: [{collection: 'scenes'}]},
// 			{collection: 'songs', doc: 'ZaDy9Hzhzch2Z11hueni', storeAs: 'song'},
// 		]),
// 	connect((state) => ({
// 		songScenes: state.firestore.data.songScenes,
// 		songTacks: state.firestore.data.songTracks,
// 		song: state.firestore.data.song,
// 	}))
// 	)(Song)
	
	
	