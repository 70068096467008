import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux'

import './index.scss';

function Tempo(props) {
	const [tempoTimer, setTempoTimer] = useState();
	const [hideTempo, setHideTempo] = useState(false);

	useEffect(() => {
		clearTimeout(tempoTimer);
		setHideTempo(false)

		let timer = setTimeout(() => {
			setHideTempo(true)
		}, 300)
		setTempoTimer(timer)

		console.log('changing quantize size');
	}, [props.tempo])

	return (
		<div className={`tempo ${hideTempo ? 'hide' :  ''}`}>
			{props.tempo}
		</div>
	)
}

export default connect((state) => ({
	tempo: state.Sequencer.tempo,
}))(Tempo);