import {store} from './../Store'
import sequenceHandler from './../Handlers/sequenceHandler.js'
import dbHandler from './../Handlers/dbHandler.js'

export const setActiveSceneTo = (sceneNum) => {
	// console.log(sceneNum);
	// console.log(store.getState().SongData.scenes);
		for (var i = 0; i < store.getState().SongData.scenes[sceneNum].tracks.length; i++) {
			var track = store.getState().SongData.scenes[sceneNum].tracks[i]

			console.log(track);

			// set loop start + loop length
				sequenceHandler.setTrackLoopLengthAndStart(track.id, track.loopstart, track.looplength)

			// add notes to tracks
				for (var p = 0; p < track.notes.length; p++) {
					sequenceHandler.addNoteToTrack(track.id, track.notes[p])
				}
		}

		const sceneId = store.getState().SongData.scenes[sceneNum].id;
		store.dispatch({type: 'SET_ACTIVE_SCENE', sceneId: sceneId})

}

export const addNoteToActiveScene = (trackId, note) => {
	// add note to sequencer
		sequenceHandler.addNoteToTrack(trackId, note)
	
	// add note to db
		dbHandler.addNoteToTrack(trackId, note)
}